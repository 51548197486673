import {
  ALLOWED_MODULES_FOR_DASHBOARD_CONTRIBUTOR,
  apiRequestResponseMsg,
  defaultSsrSettings,
  modalName as commonModalName,
  moduleNames,
} from '@va/constants';
import {
  createSsrSettings,
  GET_SSR_SETTINGS_FAILED,
  getVideoTrackingCode,
  PROMPT_SSR_FEATURE_FAILED,
  PROMPT_SSR_FEATURE_SUCCEEDED,
  promptSsrFeature,
  requestSettings,
} from '@va/dashboard/actions/api';
import {
  GET_MODULE_PERMISSIONS,
  getModulePermissionsSucceeded,
  HANDLE_COMPANY_DETAILS_MODAL,
  PROMPT_SSR_FEATURE_FLOW,
  START_UPGRADE_PROCESS,
} from '@va/dashboard/actions/app';
import { addNotification, CLOSE_MODAL, closeModal, openModal } from '@va/dashboard/actions/ui';
import { getApiRequest } from '@va/dashboard/selectors/api';
import { getInstanceId, isTrackingCodeInstalled } from '@va/dashboard/selectors/app';
import { getSsrSettingsSelector, getWebsite } from '@va/dashboard/selectors/core';
import { getUserRole } from '@va/standalone/shared/selectors';
import { UserRole } from '@va/types/website';
import { LocalStorage } from '@va/util/helpers';
import NotificationLimit from 'common/components/misc/NotificationLimit';
import React from 'react';
import { all, put, select, take, takeLatest } from 'redux-saga/effects';
import { startUpgradeProcessSaga } from './upgrade';

export function* watchers() {
  yield all([
    takeLatest(PROMPT_SSR_FEATURE_FLOW, promptSsrFeatureFlow),
    takeLatest(HANDLE_COMPANY_DETAILS_MODAL, handleCompanyDetailsModal),
    takeLatest(START_UPGRADE_PROCESS, startUpgradeProcessSaga),
    takeLatest(GET_MODULE_PERMISSIONS, handleModulePermissions),
  ]);
}

export function* promptSsrFeatureFlow(action) {
  try {
    yield put(promptSsrFeature(action.value));
    const promptAction = yield take([PROMPT_SSR_FEATURE_SUCCEEDED, PROMPT_SSR_FEATURE_FAILED, CLOSE_MODAL]);
    if (promptAction.type === PROMPT_SSR_FEATURE_SUCCEEDED) {
      if (action.value) {
        yield put(requestSettings());
        yield put(closeModal(commonModalName.installSsr));
        yield put(getVideoTrackingCode());
        yield put(openModal(commonModalName.addWixSsrCode));
      } else {
        yield put(closeModal(commonModalName.installSsr));
      }
    }
  } catch (e) {
    //
  }
}

function* handleCompanyDetailsModal() {
  try {
    const website = yield select(getWebsite);
    const fFlags = website.frontendFlags;
    if (!(fFlags && fFlags[commonModalName.updateCompanyDetail])) {
      if (website.businessSector === null) {
        yield put(openModal(commonModalName.updateCompanyDetail));
      }
    }
  } catch (e) {
    console.error(e);
  }
}

/**
 * @param {int} limitPercentage - 50, 75, 90 or 100
 * @param {string} type - "error", "warning", "success" or "info"
 * @param {boolean} showOnce
 */
export function* showLimitNotification(limitPercentage, type, showOnce = false, subTitleText = '') {
  const websiteId = yield select(getInstanceId);
  const featurePermissions = yield select(getApiRequest, 'getFeaturePermissions');
  const limitFromPermissions = featurePermissions?.visits?.limit ?? 0;

  const content = {
    level: type,
    autoDismiss: 0,
    dismissible: 'button',
    children: React.createElement(NotificationLimit, { limit: limitFromPermissions, subTitleText: subTitleText }),
  };

  if (showOnce) {
    if (!getLimitNotification(limitPercentage, websiteId)) {
      yield put(addNotification(content));
    }
  } else {
    yield put(addNotification(content));
  }
}

export function setLimitNotificationShown(limitPercentage, websiteId) {
  try {
    const newDate = new Date();
    const currentYear = newDate.getFullYear();
    const currentMonth = newDate.getMonth();
    LocalStorage.setItem(
      `visa-limit-${limitPercentage}-for-${websiteId}-in-${currentYear}-${currentMonth}-showed`,
      '1',
    );
  } catch (e) {
    // do nothing
  }
}

export function getLimitNotification(limitPercentage, websiteId) {
  try {
    const newDate = new Date();
    const currentYear = newDate.getFullYear();
    const currentMonth = newDate.getMonth();
    return LocalStorage.getItem(
      `visa-limit-${limitPercentage}-for-${websiteId}-in-${currentYear}-${currentMonth}-showed`,
    );
  } catch (e) {
    return null;
  }
}

export function* checkSsrSettings() {
  yield take([GET_SSR_SETTINGS_FAILED]);
  const trackingCodeInstalled = yield select(isTrackingCodeInstalled);
  if (!trackingCodeInstalled) return;

  const ssrSettingsResponse = yield select(getSsrSettingsSelector);

  if (ssrSettingsResponse?.error.message === apiRequestResponseMsg.NOT_FOUND_SSR_SETTINGS) {
    yield put(createSsrSettings(defaultSsrSettings));
  }
}

export function* handleModulePermissions() {
  // TODO Make sure this still works for all of 3as, wix, standalone
  const userRole = yield select(getUserRole);

  if (userRole === UserRole.DASHBOARD) {
    yield put(getModulePermissionsSucceeded({ data: dashboardModulePermissions }));
    return;
  }

  // all enabled
  yield put(getModulePermissionsSucceeded({ data: [] }));

  // TODO Integrate other permissions
}

const dashboardModulePermissions = Object.keys(moduleNames).map((k) => {
  return {
    module: k,
    disabled: isModuleDisabledForDashboardContributor(k),
  };
});

function isModuleDisabledForDashboardContributor(moduleName) {
  return !ALLOWED_MODULES_FOR_DASHBOARD_CONTRIBUTOR.includes(moduleName);
}
