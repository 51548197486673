import { removeNotificationV2 } from '@va/dashboard/actions/ui';
import { fontWeights, IconWithBackground, Paragraph } from '@va/ui/design-system';
import { EventBus } from '@va/util/misc';
import classNames from 'classnames';
import { useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ADD_NOTIFICATION_EVENT } from '@va/constants';
import { addNotificationV2 } from '@va/dashboard/actions/ui';
import { getNotificationV2 } from '@va/dashboard/selectors/ui';
import { CloseIconWithCircle, SettingsIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { NotificationTypeEnum } from '@va/types/notification';

export const NotificationV2 = () => {
  const notification = useSelector(getNotificationV2);

  const translate = useTranslate();

  const dispatch = useDispatch();

  const removeNotificationTimeout = useRef<any>(null);

  useEffect(() => {
    if (!notification.showNotification) return;
    clearTimeout(removeNotificationTimeout?.current);
    removeNotificationTimeout.current = setTimeout(() => {
      dispatch(removeNotificationV2());
    }, notification.autoDismiss);
  }, [dispatch, notification]);

  useEffect(() => {
    const listener = EventBus.addListener(
      ADD_NOTIFICATION_EVENT,
      ({
        translationKey,
        type,
        translationKeyParams,
      }: {
        translationKey: string;
        type: NotificationTypeEnum;
        translationKeyParams: Record<string, unknown>;
      }) => {
        dispatch(
          addNotificationV2({
            text: translate(translationKey, translationKeyParams),
            autoDismiss: 3000,
            type: type,
          }),
        );
      },
    );
    return () => {
      listener.removeListener();
    };
  }, [translate, dispatch]);

  const closeIconColor = useMemo(() => {
    if (notification.type === NotificationTypeEnum.info) {
      return '#FFFFFFBF';
    }
    return '#3C3C3C';
  }, [notification.type]);

  return createPortal(
    <div
      className={classNames(
        'z-[var(--zIndex-notification)] w-full max-w-[360px] shadow-md p-18px  min-h-72px  rounded-l-full  fixed top-1/4 right-0 flex gap-3 items-center transition-transform duration-300',
        {
          'translate-x-full': !notification.showNotification,
          'translate-x-0': notification.showNotification,
          'bg-white-snow': notification.type !== NotificationTypeEnum.info,
          'bg-black': notification.type === NotificationTypeEnum.info,
        },
      )}
    >
      <IconWithBackground
        className={classNames('flex-shrink-0', {
          '!bg-green-pure': notification?.type === NotificationTypeEnum.success,
          '!bg-red-pure': notification?.type === NotificationTypeEnum.error,
          '!bg-[#7A05F0]': notification?.type === NotificationTypeEnum.info,
        })}
        icon={() => <SettingsIcon color={'white'} />}
      />
      <Paragraph
        className='break-words overflow-hidden'
        colorClassName={classNames({
          '!text-green-pure': notification?.type === NotificationTypeEnum.success,
          '!text-red-pure': notification?.type === NotificationTypeEnum.error,
          '!text-white': notification?.type === NotificationTypeEnum.info,
        })}
        weight={fontWeights.medium}
      >
        {notification?.text}
      </Paragraph>
      <button
        className='ml-auto mr-2 shrink-0 hover:opacity-80 transition-colors'
        onClick={() => {
          dispatch(removeNotificationV2());
        }}
      >
        <CloseIconWithCircle color={closeIconColor} />
      </button>
    </div>,
    document.body,
  );
};
