import { TooltipOptions } from '@va/types/tooltip';
import { fontWeights, Paragraph, ParagraphWithTooltip } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';

type ButtonOption = {
  value: string | number;
  tooltip?: string | ReactNode;
  className?: string;
  disabled?: boolean;
  tooltipProps?: TooltipOptions;
  'data-testid'?: string;
};

type ButtonWithLabel = {
  label: string;
  icon?: ReactElement;
  iconPosition?: 'start' | 'end';
  content?: never;
} & ButtonOption;

type ButtonWithContent = {
  label?: never;
  icon?: never;
  iconPosition?: never;
  content: (isSelected: boolean, className: string) => ReactNode;
} & ButtonOption;

function isWithlabel(buttonOption: ButtonWithContent | ButtonWithLabel): buttonOption is ButtonWithLabel {
  return typeof buttonOption.label === 'string';
}

export type TabbedButtonProps = {
  buttonOptions: Array<ButtonWithLabel | ButtonWithContent>;
  selectedValue: string | number;
  onChange: Function;
  className?: string;
  textClassName?: string;
  activeButtonClassName?: string;
  inactiveButtonClassName?: string;
  size?: 'small' | 'large';
};

export const TabbedButton: React.FC<TabbedButtonProps> = ({
  buttonOptions,
  selectedValue,
  onChange,
  className,
  textClassName,
  activeButtonClassName,
  inactiveButtonClassName,
  size = 'large',
}) => {
  return (
    <div
      className={classNames(
        'flex h-54 p-[3px] w-full bg-gray-concrete',
        {
          'rounded-13.5': size === 'large',
          'h-42px rounded-12': size === 'small',
        },
        className,
      )}
    >
      {buttonOptions.map((buttonOption, index) => {
        const { value, className, disabled, iconPosition, 'data-testid': dataTestId } = buttonOption;
        const isSelected = selectedValue === value;
        return (
          <div
            key={index}
            className={classNames(
              'flex grow justify-center items-center px-4 truncate',
              {
                'bg-white rounded-12 cursor-default': isSelected,
                'hover:bg-gray-mercury active:bg-gray-alto cursor-pointer': !isSelected && !disabled,
                'cursor-not-allowed text-gray-silver': disabled,
                'text-primary': isSelected,
                'rounded-13.5': size === 'large',
                'rounded-[9px]': size === 'small',
              },
              isSelected && !disabled && activeButtonClassName,
              !isSelected && inactiveButtonClassName,
              className,
            )}
            onClick={() => {
              if (disabled) return;
              onChange(value);
            }}
            data-testid={dataTestId}
          >
            {isWithlabel(buttonOption) ? (
              <ButtonWithLabel
                {...buttonOption}
                isSelected={isSelected}
                textClassName={textClassName}
                iconPosition={iconPosition}
              />
            ) : (
              <ButtonWithContent {...buttonOption} isSelected={isSelected} textClassName={textClassName} />
            )}
          </div>
        );
      })}
    </div>
  );
};

type Extra = {
  isSelected: boolean;
  textClassName?: string;
};
const ButtonWithLabel = (props: ButtonWithLabel & Extra) => {
  const { tooltip, tooltipProps, label, icon, isSelected, textClassName, iconPosition } = props;
  if (tooltip) {
    return (
      <TooltipWrapper disabled={!isSelected} trigger='click' content={tooltip} {...tooltipProps}>
        <div
          className={classNames('flex gap-2 items-center', {
            'flex-row-reverse': iconPosition === 'start',
          })}
        >
          {label && (
            <Paragraph
              weight={fontWeights.medium}
              colorClassName={isSelected ? 'text-primary' : undefined}
              className={classNames(
                'truncate border-b border-dashed',
                { 'border-gray-silver': isSelected, 'border-transparent': !isSelected },
                textClassName,
              )}
            >
              {label}
            </Paragraph>
          )}
          {icon}
        </div>
      </TooltipWrapper>
    );
  }

  return (
    <div className={classNames('flex gap-2 items-center')}>
      {label && (
        <ParagraphWithTooltip
          weight={fontWeights.medium}
          colorClassName={isSelected ? 'text-primary' : undefined}
          className={textClassName}
        >
          {label}
        </ParagraphWithTooltip>
      )}
      {icon}
    </div>
  );
};

const ButtonWithContent = (props: ButtonWithContent & Extra) => {
  // TODO Integrate tooltip props - not needed at the moment
  const { tooltip, tooltipProps, content, isSelected } = props;

  return content(
    isSelected,
    classNames('', {
      'border-gray-silver': isSelected,
      'border-transparent': !isSelected,
    }),
  );
};
