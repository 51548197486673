import { fontWeights, paragraphSizes, ParagraphWithTooltip } from '@va/ui/design-system';
import classNames from 'classnames';
import React, { forwardRef, MouseEventHandler } from 'react';

export type InfoSectionItemProps = {
  icon: React.ReactNode;
  title: string | React.ReactNode;
  label?: string;
  rightNode?: React.ReactNode;
  className?: string;
  textColReverse?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  dataTestId?: string;
  size?: 'small' | 'large';
};

export const InfoSectionItem = forwardRef<HTMLDivElement, InfoSectionItemProps>(
  ({ icon, title, label, rightNode, className, textColReverse = false, onClick, dataTestId, size = 'large' }, ref) => {
    return (
      <div
        ref={ref}
        className={classNames('w-full p-12px flex items-center gap-3 bg-white-snow ', className, {
          'h-72': size === 'large',
        })}
        onClick={onClick}
        data-testid={dataTestId}
      >
        <div className='shrink-0'>{icon}</div>
        <div
          className={classNames('w-full overflow-hidden flex', {
            'flex-col': !textColReverse,
            'flex-col-reverse': textColReverse,
          })}
        >
          <ParagraphWithTooltip
            weight={fontWeights.medium}
            className='truncate'
            colorClassName='text-text-primary opacity-75'
            size={paragraphSizes.tiny}
          >
            {label}
          </ParagraphWithTooltip>
          {typeof title === 'string' ? (
            <ParagraphWithTooltip colorClassName='text-text-primary' weight={fontWeights.medium} className='truncate'>
              {title}
            </ParagraphWithTooltip>
          ) : (
            title
          )}
        </div>
        {rightNode && <div className='ml-auto'>{rightNode}</div>}
      </div>
    );
  },
);
