import { FRONTEND_FLAGS_ADDED_BEFORE_FIRST_LOGIN, ONE_HOUR, ONE_MINUTE, Platform, PLATFORM_IDS } from '@va/constants';
import { FrontendFlagsType } from '@va/types/account';
import {
  AnnouncementCategoriesType,
  AnnouncementsMessageTypeEnum,
  AnnouncementsPlatformTypeEnum,
  AnnouncementsSubscriptionTypeEnum,
  AnnouncementType,
  AnnouncementUserTypeEnum,
  DefaultAnnouncementType,
} from '@va/types/announcements';
import { getParameterByName, isInThePast, transformCommaSeparatedValuesToArray } from '@va/util/helpers';

type WebsiteDataType = {
  isWebsiteOwner: boolean;
  hasPremiumSubscription: boolean;
  platform: number | string;
  installDate: number | string;
  numberOfMonthlyVisits: number | string;
  ipCountryISO: string;
  lastWebsiteBasedModalViewedAt?: number;
};

type AccountDataType = {
  frontendFlags: FrontendFlagsType;
  isAgencyOwner: boolean;
  isOnCustomDomain: boolean;
  lastAccountBasedModalViewedAt?: number;
  isInternalUser?: boolean;
};

const ACCOUNT_BASED_MODAL_INTERVAL = 2 * ONE_HOUR;
const WEBSITE_BASED_MODAL_INTERVAL = 20 * ONE_MINUTE;

export const getAnnouncementsToBeDisplayed = (
  announcements: AnnouncementType[],
  accountData: AccountDataType,
  websiteData: WebsiteDataType | undefined,
) => {
  const bubbleId = getParameterByName('inapp_bubble');

  let visibleAnnouncementsInOrder = [];

  if (!bubbleId) {
    const filteredAnnouncements = getFilteredAnnouncements(announcements, accountData, websiteData);
    visibleAnnouncementsInOrder = getVisibleAnnouncementsInOrder(filteredAnnouncements);
  } else {
    const announcementToBeForcefullyDisplayed = announcements.find((item) => item.id === bubbleId);
    if (!announcementToBeForcefullyDisplayed) {
      console.error(`Bubble with id ${bubbleId} not found`);
      return [];
    }

    visibleAnnouncementsInOrder = [{ ...announcementToBeForcefullyDisplayed, forceDisplay: true }];
  }

  return visibleAnnouncementsInOrder;
};

export const getFilteredAnnouncements = (
  announcements: AnnouncementType[],
  accountData: AccountDataType,
  websiteData: WebsiteDataType | undefined,
) => {
  const { frontendFlags, isAgencyOwner, isOnCustomDomain, lastAccountBasedModalViewedAt, isInternalUser } = accountData;

  return announcements
    .map((announcement) => {
      const { platform, userType } = announcement.categories;

      const hasBeenSeen = frontendFlags[announcement.id];
      if (hasBeenSeen) return null;

      const isAccountBased = isAccountBasedAnnouncement(platform, userType);

      if (isAccountBased) {
        return handleAccountBasedAnnouncement({
          lastAccountBasedModalViewedAt,
          announcement,
          frontendFlags,
        });
      }

      return handleWebsiteBasedAnnouncement({
        announcement,
        isAgencyOwner,
        isInternalUser,
        isOnCustomDomain,
        websiteData,
        accountData,
      });
    })
    .filter((item) => item) as AnnouncementType[];
};

export function isAccountBasedAnnouncement(
  platform: AnnouncementsPlatformTypeEnum[],
  userType: AnnouncementUserTypeEnum[],
) {
  if (platform.includes(AnnouncementsPlatformTypeEnum.all) && userType.includes(AnnouncementUserTypeEnum.all))
    return true;
  return false;
}

export function announcementCategoriesTransformer(categories: AnnouncementCategoriesType) {
  return {
    ...categories,
    platform: transformCommaSeparatedValuesToArray(categories.platform),
    userType: transformCommaSeparatedValuesToArray(categories.userType),
    messageType: transformCommaSeparatedValuesToArray(categories.messageType?.toLowerCase() ?? ''),
    subscriptionType: transformCommaSeparatedValuesToArray(categories.subscriptionType),
  };
}

export function announcementsMapper(announcements: DefaultAnnouncementType[]) {
  return announcements.map((anouncement) => ({
    ...anouncement,
    categories: announcementCategoriesTransformer(anouncement.categories),
  }));
}

export function websiteMatchesSubscription(
  hasPremiumSubscription: boolean,
  subscriptionType: AnnouncementsSubscriptionTypeEnum[],
) {
  if (subscriptionType.includes(AnnouncementsSubscriptionTypeEnum.all) || subscriptionType.length === 0) return true;
  if (subscriptionType.includes(AnnouncementsSubscriptionTypeEnum.free) && !hasPremiumSubscription) return true;
  if (subscriptionType.includes(AnnouncementsSubscriptionTypeEnum.premium) && hasPremiumSubscription) return true;
  return false;
}

export function websiteMatchesPlatform(platform: AnnouncementsPlatformTypeEnum[], websitePlatform: number | string) {
  if (platform.includes(AnnouncementsPlatformTypeEnum.all) || platform.length === 0) return true;
  const standalonePlatformId = typeof websitePlatform === 'number' && websitePlatform === PLATFORM_IDS.CUSTOM;
  const standalonePlatformName =
    typeof websitePlatform === 'string' && websitePlatform.toLowerCase() === Platform.CUSTOM.toLowerCase();
  const isStandaloneWebsite = standalonePlatformId || standalonePlatformName;
  if (platform.includes(AnnouncementsPlatformTypeEnum.standalone) && isStandaloneWebsite) return true;
  const wixPlatformId = typeof websitePlatform === 'number' && websitePlatform === PLATFORM_IDS.WIX;
  const wixPlatformName =
    typeof websitePlatform === 'string' && websitePlatform.toLowerCase() === Platform.WIX.toLowerCase();
  const isWixWebsite = wixPlatformId || wixPlatformName;
  if (platform.includes(AnnouncementsPlatformTypeEnum.wix) && isWixWebsite) return true;
  return false;
}

export function websiteMatchesUserType(
  userType: AnnouncementUserTypeEnum[],
  isWebsiteOwner: boolean,
  isAgencyOwner: boolean,
  isOnCustomDomain: boolean,
  isInternalUser?: boolean,
) {
  if (userType.includes(AnnouncementUserTypeEnum.all) || userType.length === 0) return true;
  if (userType.includes(AnnouncementUserTypeEnum.internal) && isInternalUser) return true;
  if (userType.includes(AnnouncementUserTypeEnum.owner) && isWebsiteOwner) return true;
  if (userType.includes(AnnouncementUserTypeEnum.contributor) && !isWebsiteOwner) return true;
  if (userType.includes(AnnouncementUserTypeEnum.awl) && isOnCustomDomain) return true;
  if (userType.includes(AnnouncementUserTypeEnum.awl_owner) && isAgencyOwner && isOnCustomDomain) return true;
  if (userType.includes(AnnouncementUserTypeEnum.awl_contributor) && !isAgencyOwner && isOnCustomDomain) return true;
  return false;
}

export function websiteMatchesGeolocation(geolocation: string | null | undefined, websiteIpCountryISO: string) {
  if (!geolocation || geolocation.toLowerCase() === 'all') return true;
  return geolocation.toLowerCase() === websiteIpCountryISO.toLowerCase();
}

export function websiteMatchesInstallDate(installDate: string | number, websiteInstallDate: number | string) {
  return new Date(Number(installDate)).getTime() <= new Date(websiteInstallDate).getTime(); // TODO Check if unix
}

export function getVisibleAnnouncementsInOrder(announcements: AnnouncementType[]) {
  const visibleAnnouncements: AnnouncementType[] = [];

  const firstLoginAnnouncement = announcements.find((announcement) => isFirstLoginAnnouncement(announcement));
  const accoutBasedAnnouncement = announcements.find((announcement) => announcement.isAccountBased);
  const websiteBasedAnnouncement = announcements.find((announcement) => !announcement.isAccountBased);

  if (firstLoginAnnouncement) {
    visibleAnnouncements.push(firstLoginAnnouncement);
  }

  if (accoutBasedAnnouncement && !firstLoginAnnouncement) {
    visibleAnnouncements.push(accoutBasedAnnouncement);
  }

  if (websiteBasedAnnouncement) {
    visibleAnnouncements.push(websiteBasedAnnouncement);
  }
  return visibleAnnouncements;
}

export function handleAccountBasedAnnouncement({
  lastAccountBasedModalViewedAt,
  announcement,
  frontendFlags,
}: {
  lastAccountBasedModalViewedAt: number | undefined;
  announcement: AnnouncementType;
  frontendFlags: FrontendFlagsType;
}) {
  if (isFirstLoginAnnouncement(announcement) && !isFirstLoginForUser(frontendFlags)) return null;

  const hasPassedSufficientTimeForAccountBasedModal = lastAccountBasedModalViewedAt
    ? isInThePast(Number(lastAccountBasedModalViewedAt * 1000), ACCOUNT_BASED_MODAL_INTERVAL)
    : undefined;

  if (hasPassedSufficientTimeForAccountBasedModal === false) return null;

  return {
    ...announcement,
    isAccountBased: true,
  };
}

export function handleWebsiteBasedAnnouncement({
  isAgencyOwner,
  isOnCustomDomain,
  isInternalUser,
  websiteData,
  announcement,
  accountData,
}: {
  announcement: AnnouncementType;
  isInternalUser: boolean | undefined;
  isOnCustomDomain: boolean;
  isAgencyOwner: boolean;
  websiteData: WebsiteDataType | undefined;
  accountData: AccountDataType;
}) {
  if (!websiteData) return null;

  const { subscriptionType, numberOfMonthlyVisits, geolocation, userType, installDate, platform } =
    announcement.categories;
  const { frontendFlags } = accountData;

  const hasPassedSufficientTimeForWebsiteBasedModal = websiteData.lastWebsiteBasedModalViewedAt
    ? isInThePast(Number(websiteData.lastWebsiteBasedModalViewedAt * 1000), WEBSITE_BASED_MODAL_INTERVAL)
    : undefined;

  if (hasPassedSufficientTimeForWebsiteBasedModal === false) return null;

  const { isWebsiteOwner } = websiteData;

  let websiteMatchesCondition = true;

  if (subscriptionType.length !== 0) {
    websiteMatchesCondition = websiteMatchesSubscription(websiteData.hasPremiumSubscription, subscriptionType);
  }

  if (websiteMatchesCondition) {
    websiteMatchesCondition =
      !isFirstLoginAnnouncement(announcement) ||
      (isFirstLoginAnnouncement(announcement) && isFirstLoginForUser(frontendFlags));
  }

  if (websiteMatchesCondition && platform.length !== 0) {
    websiteMatchesCondition = websiteMatchesPlatform(platform, websiteData.platform);
  }

  if (websiteMatchesCondition && userType.length !== 0) {
    websiteMatchesCondition = websiteMatchesUserType(
      userType,
      isWebsiteOwner,
      isAgencyOwner,
      isOnCustomDomain,
      isInternalUser,
    );
  }

  if (websiteMatchesCondition && numberOfMonthlyVisits) {
    websiteMatchesCondition = Number(numberOfMonthlyVisits) >= Number(websiteData.numberOfMonthlyVisits);
  }

  if (websiteMatchesCondition && geolocation) {
    websiteMatchesCondition = websiteMatchesGeolocation(geolocation, websiteData.ipCountryISO);
  }

  if (websiteMatchesCondition && installDate) {
    websiteMatchesCondition = websiteMatchesInstallDate(installDate, websiteData.installDate);
  }

  return websiteMatchesCondition ? { ...announcement, isAccountBased: false } : null;
}

export const isFirstLoginForUser = (frontendFlags: FrontendFlagsType) => {
  const filteredKeys = Object.keys(frontendFlags).filter(
    (key) => !FRONTEND_FLAGS_ADDED_BEFORE_FIRST_LOGIN.includes(key),
  );
  return filteredKeys.length === 0;
};

export const isFirstLoginAnnouncement = (announcement: AnnouncementType) =>
  announcement.categories.messageType.includes(AnnouncementsMessageTypeEnum.firstlogin);
